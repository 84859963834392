import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'SvgIcon'
})
export default class SvgIcon extends Vue {
    @Prop({ default: null }) readonly src!: string;
    @Prop({ default: false }) readonly hasFill?: boolean;
    @Prop({ default: true }) readonly growByHeight?: boolean;
    @Prop({ default: true }) readonly svgHeight?: string;
    @Prop({ default: true }) readonly svgWidth?: string;
    @Prop({ default: null }) readonly svgColor?: string;

    svgContent: string | null = null;
    fetchPromise: Promise<Response> | null = null;

    recursivelyRemoveFill(element: Element) {
        if (!element) {
            return;
        }

        element.removeAttribute('fill');
        Array.prototype.forEach.call(element.children, (child) => {
            this.recursivelyRemoveFill(child);
        });
    }

    buildSvg() {
        const svgElement = this.$el.firstElementChild;
        if (svgElement && svgElement.nodeName === 'svg') {
            const viewBoxAttribute = svgElement.getAttribute('viewBox');

            if (viewBoxAttribute) {
                // Use `viewBox` attribute to get the svg's inherent width and height
                const viewBox = viewBoxAttribute.split(' ').map((n) => Number(n));

                const widthToHeight = (viewBox[2] / viewBox[3]).toFixed(2);
                if (this.hasFill) {
                    // Recursively remove all fill attribute of element and its nested children
                    this.recursivelyRemoveFill(svgElement);
                }

                // Set width and height relative to font size if growByHeight is true, height set to 1em else width set to 1em and remaining is calculated based on widthToHeight ratio
                if (this.growByHeight) {
                    svgElement.setAttribute('height', '1em');
                    svgElement.setAttribute('width', `${widthToHeight}em`);
                } else {
                    svgElement.setAttribute('width', '1em');
                    svgElement.setAttribute('height', `${1 / Number(widthToHeight)}em`);
                }
                if (this.svgHeight) {
                    svgElement.setAttribute('height', this.svgHeight + 'px');
                }
                if (this.svgWidth) {
                    svgElement.setAttribute('width', this.svgWidth + 'px');
                }
                if (this.svgColor) {
                    svgElement.setAttribute('fill', this.svgColor);
                    svgElement.setAttribute('color', this.svgColor);
                }
                svgElement.classList.add('svg-class');
            }
        }
    }

    created() {
        this.fetchPromise = fetch(this.src);
    }

    async mounted() {
        await this.$nextTick();
        this.fetchPromise?.then(async (response) => {
            this.svgContent = await response.text();
            await this.$nextTick();
            this.buildSvg();
        });
    }
}
