import axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import urljoin from 'url-join';
import config from '../../config/config';
import { cache, getCustomilyAuthHeaders } from './baseApi';

const axiosInstance = axios.create({
    baseURL: urljoin(config.CUSTOMILY_BASE_URL, 'api'),
    responseType: 'json',
    adapter: cache.adapter,
    withCredentials: true,
    headers: {
        ...getCustomilyAuthHeaders()
    }
});

axiosBetterStacktrace(axiosInstance);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error?.response?.status === 401) {
            document.dispatchEvent(new Event('auth-error'));
        }
        throw error;
    }
);

export default axiosInstance;
