const config = {
    CUSTOMILY_BASE_URL: process.env.VUE_APP_CUSTOMILY_BASE_URL as string,
    CUSTOMILY_CDN_URL: process.env.VUE_APP_CUSTOMILY_CDN_URL as string,
    VUE_APP_ENV: process.env.VUE_APP_ENVIRONMENT as string,
    APP_ROOT_URL: process.env.VUE_APP_ROOT_URL as string,
    APP_SHOPIFY_BASE_URL: process.env.VUE_APP_SHOPIFY_BASE_URL as string,
    SHOPIFY_SCRIPT_URL: process.env.VUE_APP_SHOPIFY_SCRIPT_URL as string,
    // TODO: use proper URL in config files for wordpress script
    WORDPRESS_SCRIPT_URL: `${process.env.VUE_APP_CUSTOMILY_CDN_URL}/wordpress/static/scripts/customily.wordpress.script.unified.js`,
    STANDALONE_SCRIPT_URL: process.env.VUE_APP_STANDALONE_SCRIPT_URL as string,
    CUSTOMILY_JS_URL: process.env.VUE_APP_CUSTOMILY_JS_URL as string,
    APPCUES_SUFIX: process.env.VUE_APP_APPCUES_SUFIX as string
};

export default config;
