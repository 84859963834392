import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { CustomilyTemplateDto } from '@extTypes/api/customily/template';
import { getTemplates, getTemplatesCount } from '@/api/customily/product';
import { buildCloudFrontUrl } from '@/utils/url';

@Component({
    name: 'CustomilyProductsList'
})
export default class CustomilyProductsList extends Vue {
    showLoader = false;
    searchString = '';
    templatesPerPage = 10;
    currentPage = 1;
    totalTemplatesCount = 10;
    templates: CustomilyTemplateDto[] = [];
    templateHovered = 0;
    thumbsProps = {
        width: 50,
        height: 50
    };

    @Watch('currentPage')
    onCurrentPageChange() {
        this.loadProducts();
    }

    @Watch('searchString')
    onSearchStringChange() {
        this.searchProducts();
    }

    buildImageUrl(imgPath: string) {
        if (!imgPath) {
            return imgPath;
        }
        return buildCloudFrontUrl(imgPath);
    }

    onTemplateSelect(template: CustomilyTemplateDto) {
        this.$emit('select', {
            template: template
        });
    }

    async searchProducts() {
        this.currentPage = 1;
        await this.loadProducts();
    }

    async loadProducts() {
        try {
            this.showLoader = true;

            const [templates, count] = await Promise.all([
                getTemplates(this.searchString, (this.currentPage - 1) * this.templatesPerPage, this.templatesPerPage),
                getTemplatesCount(this.searchString)
            ]);
            this.templates = templates;
            this.totalTemplatesCount = count;

            this.showLoader = false;
        } catch (error) {
            this.showLoader = false;
            window.captureException(error as Error);
        }
    }

    created() {
        this.loadProducts();
    }
}
