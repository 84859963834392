import Vue from 'vue';
import VueTippy from 'vue-tippy';

Vue.use(VueTippy, {
    directive: 'tippy', // => v-tippy
    flipDuration: 0,
    placement: 'top',
    interactive: false,
    popperOptions: {
        modifiers: {
            preventOverflow: {
                enabled: false
            },
            hide: {
                enabled: false
            }
        }
    }
});
