import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as productsApi from '@/api/shopify/products';
import Loader from '@/components/Loader/Loader.vue';
import { DTO } from '@extTypes/dto/productDto';
import { Variant } from '@extTypes/shopify/product';
import { BDropdown, BModal } from 'bootstrap-vue';
import { Mutation, State } from 'vuex-class';
import { ApiUser } from '@/api/shopify/user';

import { isShopify, isWooCommerceStore } from '@/utils/stores';
import { LinkedProductsOption } from '@extTypes/enums/products';
import { productLinkedOptions } from '@/utils/constants/products';
import { Store, StoreType } from '@extTypes/api/customily/user';

@Component({
    name: 'ProductsList',
    components: {
        Loader: Loader
    }
})
export default class ProductsList extends Vue {
    @State('user') user!: ApiUser;
    @State('store') stateStore!: string;
    @Mutation('setStore') setStore!: (store: string | null) => void;

    @Prop({ default: true }) readonly showProductsSync!: boolean;
    @Prop() readonly storeTypes!: StoreType[];
    @Prop({ default: true }) readonly updateStoreState!: boolean;
    @Prop({ default: true }) readonly showBackButton!: boolean;
    @Prop({ default: false }) readonly showVariants!: boolean;
    @Prop({ default: false }) readonly askQuantity!: boolean;

    // eslint-disable-next-line unicorn/prefer-module
    defaultImage: string = require('@/assets/img/placeholder_customily.png');
    showLoader = false;
    searchString = '';
    productsPerPage = 10;
    currentPage = 1;
    totalProductsCount = 10;
    products: DTO.ShopifyProductList[] = [];
    thumbsProps = {
        width: 50,
        height: 50
    };

    allowProductsSync = false;
    synchronizationError = false;
    productsSyncInProgress = false;
    syncStatusCheckCurrentInterval: number | null = null;
    syncProductsButtonVariant = 'secondary';

    allowProductsConfigImport = true;
    productsConfigImportError = false;
    productsConfigImportInProgress = false;
    productsConfigImportInterval: number | null = null;
    productsConfigImportButtonVariant = 'secondary';
    canShowConfigImportBtn: undefined | boolean = false;
    canShowProductsSync: undefined | boolean = false;

    // Selected product and variant
    quantity = 1;
    selectedProduct: DTO.ShopifyProductList | null = null;
    selectedVariant: Variant | null = null;
    selectedStore: string | null = null;

    onlyLinkedProducts = LinkedProductsOption.All;
    productLinkedOptions = productLinkedOptions;

    productsFiltersLabel = 'All products';
    isPodSelectionFlow = this.$router.currentRoute.query?.isPodSelectionFlow === 'true';
    podSelectionComponentName = 'PODSelection';

    @Watch('onlyLinkedProducts')
    onOnlyLinkedProductsChange() {
        const selectedOption = this.productLinkedOptions.find((opt) => opt.value === this.onlyLinkedProducts);
        this.productsFiltersLabel = selectedOption ? selectedOption.text : 'All products';
    }

    $refs!: {
        storesDropdown: BDropdown;
        quantityModal: BModal;
    };

    get currentStore(): string {
        return this.selectedStore || this.stateStore;
    }

    get stores(): Store[] {
        const userStores = this.user?.customilyUser.Stores || [];
        return this.storeTypes ? userStores.filter((s) => this.storeTypes!.includes(s.Type)) : userStores;
    }

    @Watch('currentPage')
    onCurrentPageChange() {
        this.loadProducts();
    }

    @Watch('currentStore')
    onStoreChange() {
        this.setCanShowConfigImportBtn();
        this.setCanShowProductsSync();
        this.loadProducts();
    }

    @Watch('searchString')
    onSearchStringChange() {
        this.searchProducts();
    }

    onProductSelect(product: DTO.ShopifyProductList) {
        this.$emit('product-select', {
            product: product,
            store: this.currentStore,
            isPodSelectionFlow: this.isPodSelectionFlow
        });
    }

    onQtyChange() {
        if (this.quantity < 1) {
            this.quantity = 1;
        }
    }

    onStoresDropdownClick() {
        this.$refs.storesDropdown.show();
    }

    onVariantSelect(product: DTO.ShopifyProductList, variant: Variant) {
        if (this.askQuantity) {
            this.selectedProduct = product;
            this.selectedVariant = variant;
            this.$refs.quantityModal.show();
        } else {
            this.$emit('variant-select', {
                product: product,
                variant: variant,
                quantity: 1
            });
        }
    }

    onQuantityEnter() {
        if (this.selectedProduct && this.selectedVariant) {
            this.$emit('variant-select', {
                product: this.selectedProduct,
                variant: this.selectedVariant,
                quantity: this.quantity
            });
        } else {
            console.warn('No product or variant available for this quantity!');
        }
    }

    setStoreUrl(store: string) {
        if (this.updateStoreState) {
            this.setStore(store);
            return;
        }
        this.selectedStore = store;
    }

    onBackClick() {
        this.$emit('back');
    }

    async setCanShowConfigImportBtn() {
        const status = await productsApi.getProductsConfigImportStatus(this.currentStore);
        this.canShowConfigImportBtn =
            (status.import === 'not-imported' || status.import === 'importing' || status.import === 'error') &&
            isWooCommerceStore(this.currentStore, this.user.customilyUser.Stores);
    }

    setCanShowProductsSync() {
        this.canShowProductsSync =
            this.showProductsSync &&
            !isShopify(this.currentStore, this.user.customilyUser.Stores) &&
            !isWooCommerceStore(this.currentStore, this.user.customilyUser.Stores);
    }

    getVariantBadges(product: DTO.ShopifyProductList, variant: Variant) {
        const result = [];

        if (variant && product.variationOptions) {
            if (variant.option1) {
                result.push({
                    id: variant.id + 1,
                    value: variant.option1,
                    option: product.variationOptions.find((opt) => opt.position === 1)?.name
                });
            }

            if (variant.option2) {
                result.push({
                    id: variant.id + 2,
                    value: variant.option2,
                    option: product.variationOptions.find((opt) => opt.position === 2)?.name
                });
            }

            if (variant.option3) {
                result.push({
                    id: variant.id + 3,
                    value: variant.option3,
                    option: product.variationOptions.find((opt) => opt.position === 3)?.name
                });
            }

            if (variant.option4) {
                result.push({
                    id: variant.id + 4,
                    value: variant.option4,
                    option: product.variationOptions.find((opt) => opt.position === 4)?.name
                });
            }

            if (variant.option5) {
                result.push({
                    id: variant.id + 5,
                    value: variant.option5,
                    option: product.variationOptions.find((opt) => opt.position === 5)?.name
                });
            }

            if (variant.option6) {
                result.push({
                    id: variant.id + 6,
                    value: variant.option6,
                    option: product.variationOptions.find((opt) => opt.position === 6)?.name
                });
            }

            if (variant.option7) {
                result.push({
                    id: variant.id + 7,
                    value: variant.option7,
                    option: product.variationOptions.find((opt) => opt.position === 7)?.name
                });
            }

            if (variant.option8) {
                result.push({
                    id: variant.id + 8,
                    value: variant.option8,
                    option: product.variationOptions.find((opt) => opt.position === 8)?.name
                });
            }

            if (variant.option9) {
                result.push({
                    id: variant.id + 9,
                    value: variant.option9,
                    option: product.variationOptions.find((opt) => opt.position === 9)?.name
                });
            }

            if (variant.option10) {
                result.push({
                    id: variant.id + 10,
                    value: variant.option10,
                    option: product.variationOptions.find((opt) => opt.position === 10)?.name
                });
            }

            if (variant.option11) {
                result.push({
                    id: variant.id + 11,
                    value: variant.option11,
                    option: product.variationOptions.find((opt) => opt.position === 11)?.name
                });
            }

            if (variant.option12) {
                result.push({
                    id: variant.id + 12,
                    value: variant.option12,
                    option: product.variationOptions.find((opt) => opt.position === 12)?.name
                });
            }

            if (variant.option13) {
                result.push({
                    id: variant.id + 13,
                    value: variant.option13,
                    option: product.variationOptions.find((opt) => opt.position === 13)?.name
                });
            }

            if (variant.option14) {
                result.push({
                    id: variant.id + 14,
                    value: variant.option14,
                    option: product.variationOptions.find((opt) => opt.position === 14)?.name
                });
            }

            if (variant.option15) {
                result.push({
                    id: variant.id + 15,
                    value: variant.option15,
                    option: product.variationOptions.find((opt) => opt.position === 15)?.name
                });
            }
        }

        return result;
    }

    async syncProductsNow() {
        try {
            this.synchronizationError = false;
            this.showLoader = true;
            await productsApi.startProductSync(this.currentStore);
            await this.refreshProductSyncStatus();
            this.showLoader = false;
            this.loadProducts();
        } catch (error) {
            this.showLoader = false;
            window.captureException(error as Error);
        }
    }

    async importProductsNow() {
        try {
            await productsApi.startImportProductsConfig(this.currentStore);
            await this.refreshProductConfigImportStatus();
            await this.loadProducts();
        } catch (error) {
            window.captureException(error as Error);
        }
    }

    async filterProducts(onlyLinkedProducts = LinkedProductsOption.All) {
        this.onlyLinkedProducts = onlyLinkedProducts as LinkedProductsOption;
        this.saveLastSessionProductFilters();
        await this.searchProducts();
    }

    async searchProducts() {
        this.currentPage = 1;
        await this.loadProducts();
    }

    async loadProducts() {
        if (!this.allowProductsSync) {
            return;
        }

        try {
            this.showLoader = true;
            const response = await productsApi.getProducts(
                this.searchString,
                (this.currentPage - 1) * this.productsPerPage,
                this.productsPerPage,
                this.showVariants,
                this.onlyLinkedProducts,
                this.currentStore
            );
            this.products = response.products;
            this.totalProductsCount = response.total;

            this.showLoader = false;
        } catch (error) {
            this.showLoader = false;
            window.captureException(error as Error);
        }
    }

    async refreshProductSyncStatus() {
        try {
            // Check synchronization status
            const status = await productsApi.getProductsStatus(this.currentStore);

            // If there's a synchronization error, alert the user about it
            if (status.synchronization === 'error' || status.synchronization === 'unknown') {
                this.allowProductsSync = true;
                this.synchronizationError = true;
                this.syncProductsButtonVariant = 'danger';

                if (this.syncStatusCheckCurrentInterval) {
                    clearInterval(this.syncStatusCheckCurrentInterval);
                    this.syncStatusCheckCurrentInterval = null;
                }
                this.productsSyncInProgress = false;
            }
            // If products are still synchronizing don't load them yet
            else if (status.synchronization === 'synchronizing') {
                this.allowProductsSync = false;
                this.productsSyncInProgress = true;
                this.syncProductsButtonVariant = 'secondary';

                // Clear previous interval if any
                if (this.syncStatusCheckCurrentInterval) {
                    clearInterval(this.syncStatusCheckCurrentInterval);
                    this.syncStatusCheckCurrentInterval = null;
                }
                // Refresh sync status periodically for sync status
                this.syncStatusCheckCurrentInterval = setInterval(() => {
                    this.refreshProductSyncStatus();
                }, 1000);
            } else {
                if (this.syncStatusCheckCurrentInterval) {
                    clearInterval(this.syncStatusCheckCurrentInterval);
                    this.syncStatusCheckCurrentInterval = null;
                }

                this.productsSyncInProgress = false;
                this.allowProductsSync = true;
                this.syncProductsButtonVariant = 'secondary';

                // If sync is complete, refresh products list
                this.currentPage = 1;
                await this.loadProducts();
            }
        } catch (error) {
            window.captureException(error as Error);
            this.showLoader = false;
        }
    }

    async refreshProductConfigImportStatus() {
        try {
            // Check synchronization status
            const status = await productsApi.getProductsConfigImportStatus(this.currentStore);

            // If there's a synchronization error, alert the user about it
            if (status.import === 'error' || status.import === 'unknown') {
                this.allowProductsConfigImport = true;
                this.productsConfigImportError = true;
                this.productsConfigImportButtonVariant = 'danger';

                if (this.productsConfigImportInterval) {
                    clearInterval(this.productsConfigImportInterval);
                    this.productsConfigImportInterval = null;
                }
            }
            // If products are still synchronizing don't load them yet
            else if (status.import === 'importing') {
                this.allowProductsConfigImport = false;
                this.productsConfigImportInProgress = true;
                this.productsConfigImportButtonVariant = 'secondary';

                // Clear previous interval if any
                if (this.productsConfigImportInterval) {
                    clearInterval(this.productsConfigImportInterval);
                    this.productsConfigImportInterval = null;
                }
                // Refresh sync status periodically for sync status
                this.productsConfigImportInterval = setInterval(() => {
                    this.refreshProductConfigImportStatus();
                }, 2000);
            } else {
                if (this.productsConfigImportInterval) {
                    clearInterval(this.productsConfigImportInterval);
                    this.productsConfigImportInterval = null;
                }

                this.productsConfigImportInProgress = false;
                this.allowProductsConfigImport = true;
                this.productsConfigImportButtonVariant = 'secondary';
                this.setCanShowConfigImportBtn();

                // If sync is complete, refresh products list
                this.currentPage = 1;
                await this.loadProducts();
            }
        } catch (error) {
            window.captureException(error as Error);
            this.showLoader = false;
        }
    }

    loadLastSessionProductFilters() {
        const lastSessionFilters = JSON.parse(sessionStorage.getItem('lastSessionProductFilters') || '{}');
        if (lastSessionFilters.onlyLinkedProducts) {
            this.onlyLinkedProducts = lastSessionFilters.onlyLinkedProducts;
        }
    }

    saveLastSessionProductFilters() {
        sessionStorage.setItem(
            'lastSessionProductFilters',
            JSON.stringify({
                onlyLinkedProducts: this.onlyLinkedProducts
            })
        );
    }

    async mounted() {
        this.showLoader = true;
        const isAvailableStore = this.stores.some((s) => s.Url == this.stateStore);
        if (!isAvailableStore && this.stores.length) {
            this.setStoreUrl(this.stores[0].Url);
        }
        if (this.isPodSelectionFlow) this.onlyLinkedProducts = LinkedProductsOption.NotLinked;
        else this.loadLastSessionProductFilters();
        await this.refreshProductSyncStatus();
        await this.refreshProductConfigImportStatus();
        this.showLoader = false;
        this.setCanShowConfigImportBtn();
        this.setCanShowProductsSync();
    }
}
