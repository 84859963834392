import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { groupBy } from 'lodash';
import { OptionSetDto } from '@extTypes/options/optionSet';
import { BModal } from 'bootstrap-vue';
import * as optionSetsApi from '@/api/shopify/optionSets';
import Loader from '@/components/Loader/Loader.vue';
import { Mutation, State } from 'vuex-class';
import { ApiUser } from '@/api/shopify/user';
import { StoreType } from '@extTypes/api/customily/user';
import * as storeUtil from '@/utils/stores';
import CustomilyProductsList from '@/components/CustomilyProductsList/CustomilyProductsList.vue';
import { CustomilyTemplateDto } from '@extTypes/api/customily/template';
import SetProgression from '@/components/Set/SetProgression/SetProgression.vue';
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue';

@Component({
    name: 'Sets',
    components: { Loader, CustomilyProductsList, SetProgression, SvgIcon }
})
export default class Set extends Vue {
    @State('user') user!: ApiUser;
    @State('store') store!: string;
    @State('forDesignTemplatesMode') forDesignTemplatesMode!: boolean;
    @Mutation('setDesignTemplatesMode') setDesignTemplatesMode!: (enabled: boolean) => void;

    searchString = '';
    totalSetsCount = 10;
    setsPerPage = 10;
    currentPage = 1;
    showLoader = false;
    setAboutToBeDeleted?: OptionSetDto | null = null;
    optionSets: OptionSetDto[] = [];
    productsUsingDeletedSet: {
        title: string;
        id: string;
        isDesign: boolean;
    }[] = [];
    optionSetsExportUrls: string[] = [];
    optionSetsZipFile: File | null = null;
    loaderMessage = 'Loading sets';
    exportingSets = false;

    selectedSets: OptionSetDto[] = [];
    pageLoaded = false;

    isManaged = false;
    showCloneButtun = false;
    setTaskIdentifier = '';

    // When the pagination page changes, load the new products
    @Watch('currentPage', { immediate: true })
    onPaginationChange() {
        if (this.pageLoaded) {
            localStorage.setItem('customily-current-sets-page', this.currentPage.toString());
            this.loadSets();
        }
    }

    @Watch('searchString')
    onSearchStringChange() {
        this.searchSets();
    }

    $refs!: {
        removeSetModal: BModal;
        setInUseModal: BModal;
        uploadFileModal: BModal;
        templatePickerModal: BModal;
    };

    setLoadingSetsLoaderMessage() {
        this.loaderMessage = 'Loading sets';
    }

    setExportingSetsLoaderMessage() {
        this.loaderMessage = 'Exporting sets';
    }

    async searchSets() {
        this.currentPage = 1;
        await this.getSetsCount();
        await this.loadSets();
    }

    async getSetsCount() {
        try {
            const result = await optionSetsApi.getOptionSetsCount(this.searchString, this.forDesignTemplatesMode);
            this.totalSetsCount = result.count;
        } catch (error) {
            window.captureException(error as Error);
        }
    }

    showSetDeleteDialog(setToDelete: OptionSetDto) {
        this.setAboutToBeDeleted = setToDelete;
        this.$refs.removeSetModal.show();
    }

    closeSetDeleteModal() {
        this.setAboutToBeDeleted = null;
        this.$refs.removeSetModal.hide();
    }

    async removeSet(setToDelete: OptionSetDto | null | undefined) {
        try {
            if (setToDelete) {
                this.$refs.removeSetModal.hide();
                this.showLoader = true;
                this.productsUsingDeletedSet = await optionSetsApi.getProductsWithSet(setToDelete.id);

                // Don't remove set if it's in use
                if (!this.productsUsingDeletedSet.length) {
                    optionSetsApi
                        .deleteSet(setToDelete)
                        .then(async () => {
                            await this.getSetsCount();
                            await this.loadSets();
                        })
                        .catch((error) => {
                            this.showLoader = false;
                            window.captureException(error as Error);
                        });
                } else {
                    this.showLoader = false;
                    this.$refs.setInUseModal.show();
                }
            }
        } catch (error) {
            window.captureException(error as Error);
        }
    }

    cloneOptionSet(set: OptionSetDto) {
        this.showLoader = true;
        optionSetsApi
            .cloneOptionSet(set, this.forDesignTemplatesMode)
            .then(async () => {
                await this.getSetsCount();
                await this.loadSets();
            })
            .catch((error) => {
                this.showLoader = false;
                window.captureException(error as Error);
            });
    }

    async addOptionSet() {
        // Add the new set
        const setId = await optionSetsApi.addOptionSet(
            {
                title: 'New Set',
                options: []
            },
            this.forDesignTemplatesMode
        );

        // Open the new set
        this.editOptionSet(setId);
    }

    editOptionSet(setId: number) {
        try {
            this.$router.push({
                name: 'Set',
                query: {
                    set: setId.toString()
                }
            });
        } catch (error) {
            window.captureException(error as Error);
        }
    }

    async loadSets() {
        try {
            this.showLoader = true;
            this.optionSets = await optionSetsApi.getOptionSets(
                this.searchString,
                (this.currentPage - 1) * this.setsPerPage,
                this.setsPerPage,
                this.forDesignTemplatesMode
            );
            this.showLoader = false;
        } catch (error) {
            this.showLoader = false;
            window.captureException(error as Error);
        }
    }

    async created() {
        const storedPage = localStorage.getItem('customily-current-sets-page');
        this.isManaged = this.user.customilyUser.IsManaged;
        this.showCloneButtun =
            !this.isManaged ||
            !this.user.customilyUser.ParentAccessRoles ||
            !this.user.customilyUser.ParentAccessRoles.split(',').includes('restricts-children');

        if (this.user?.customilyUser.AccessRoles.Serialized.includes('admin')) {
            this.setDesignTemplatesMode(!!this.$route.query['forDesignTemplates']);
        }

        if (storedPage) {
            try {
                this.currentPage = Number.parseInt(storedPage) || 1;
            } catch {
                // In case of failure just set the first page
                this.currentPage = 1;
            }
        }

        await this.getSetsCount();
        await this.loadSets();

        window.addEventListener('message', async (event) => {
            if (event.data.action === 'refresh-sets-list') {
                await this.getSetsCount();
                await this.loadSets();
            }
        });

        this.pageLoaded = true;
    }

    showtemplateSelectionModal() {
        this.$refs.templatePickerModal.show();
    }

    onTemplateSelect(data: { template: CustomilyTemplateDto }) {
        optionSetsApi
            .createSetFromTemplate(
                data.template.id.trim().toLowerCase(),
                this.user.customilyUser.IsManaged,
                data.template.name + ' (option set)',
                '',
                this.store
            )
            .then((result) => {
                this.setTaskIdentifier = result.taskIdentifier;
            });
    }

    onTemplatePickerHidden() {
        this.setTaskIdentifier = '';
        this.$router.go(0);
    }

    showUploadFileModal() {
        this.$refs.uploadFileModal.show();
    }

    closeUploadFileModal() {
        this.$refs.uploadFileModal.hide();
    }

    isSetIdSelected(setId: string) {
        return this.selectedSets.some((s) => s.id === setId);
    }

    toggleSetSelection(set: OptionSetDto) {
        if (this.isSetIdSelected(set.id)) {
            // Remove selected set
            const index = this.selectedSets.indexOf(set);
            this.selectedSets.splice(index, 1);
        } else {
            // Add selected set
            this.selectedSets.push(set);
        }
    }

    get isCustomStore() {
        return storeUtil.isCustomStore(this.store, this.user.customilyUser.Stores);
    }

    getSelectedSetIdsStr(): string {
        if (this.selectedSets.length > 0) {
            return this.selectedSets.map((s) => s.id).join('_');
        }
        return '';
    }

    enterExportMode() {
        this.exportingSets = true;
    }

    exitExportMode() {
        this.exportingSets = false;
    }

    async exportOptionSets() {
        try {
            this.setExportingSetsLoaderMessage();
            this.showLoader = true;

            // Group option sets by store
            const shopGroups = groupBy(this.selectedSets, (element) => {
                return element.shop;
            });

            const tasks = [];
            for (const [shop, sets] of Object.entries(shopGroups)) {
                if (sets.length > 0) {
                    tasks.push(optionSetsApi.exportOptionSets(shop, sets.map((s) => s.id).join('_')));
                }
            }

            const responses = await Promise.all(tasks);
            this.optionSetsExportUrls = responses.map((r) => r.exportUrl);

            this.showLoader = false;
            this.exitExportMode();
        } catch (error) {
            this.showLoader = false;
            window.captureException(error as Error);
        } finally {
            this.setLoadingSetsLoaderMessage();
        }
    }

    downloadOptionSetZips() {
        if (this.optionSetsExportUrls.length) {
            const urls = this.optionSetsExportUrls;

            const downloadNext = (index: number) => {
                if (index >= urls.length) {
                    return;
                }

                const a = document.createElement('a');
                a.href = urls[index];
                a.target = '_parent';

                // Add a to the doc for click to work
                (document.body || document.documentElement).appendChild(a);
                a.click();

                // Delete the temporary link
                a.parentNode?.removeChild(a);

                // Download the next file with a small timeout
                setTimeout(function () {
                    downloadNext(index + 1);
                }, 500);
            };

            // Initiate the first download.
            downloadNext(0);
        }
    }

    async importOptionSets() {
        try {
            const store = this.user.customilyUser.Stores.sort((a, b) => {
                return a.Id - b.Id;
            }).find((s) => s.Type === StoreType.Shopify || s.Type === StoreType.WooCommerce);

            if (store && this.optionSetsZipFile) {
                this.$refs.uploadFileModal.hide();
                this.showLoader = true;
                await optionSetsApi.importOptionSets(store.Url, this.optionSetsZipFile);
                await this.getSetsCount();
                await this.loadSets();
            } else {
                console.error('Please select .zip file with options to import.');
            }
        } catch (error) {
            this.showLoader = false;
            window.captureException(error as Error);
        }
    }

    goBack() {
        this.$router.go(-1);
    }
}
