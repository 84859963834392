import { setupCache } from 'axios-cache-adapter';

// Create `axios-cache-adapter` instance
export const cache = setupCache({
    maxAge: 0, // Control cache age on each request
    exclude: {
        // Don't exclude requests with query parameters
        query: false
    }
});

export const getCookieValue = (cookieName: string) => {
    const b = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
};

export const getCustomilyAuthHeaders = () => {
    // Bearer authentication should be used ONLY during development. On production the cookie is HttpOnly
    //  and is shared automatically because all the apps are under the customily.com domain
    if (process.env.NODE_ENV === 'development') {
        return {
            Authorization: 'Bearer ' + getCookieValue('userToken')
        };
    } else {
        return {};
    }
};
