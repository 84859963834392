import config from '@/config/config';

/**
 * Update/remove/add query parameter to the given URL
 * @param {string} key query parameter name
 * @param {string} value value for the query parameter
 * @param {string} url url to alter
 */
const updateQueryString = (key: string, value: string, url: string) => {
    if (!url) {
        return url;
    }

    const re = new RegExp('([?&])' + key + '=.*?(&|#|$)(.*)', 'gi');
    let hash;

    if (re.test(url)) {
        if (typeof value !== 'undefined' && value !== null) {
            return url.replace(re, '$1' + key + '=' + value + '$2$3');
        } else {
            hash = url.split('#');
            url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
            if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
                url += '#' + hash[1];
            }
            return url;
        }
    } else {
        if (typeof value !== 'undefined' && value !== null) {
            const separator = url.includes('?') ? '&' : '?';
            hash = url.split('#');
            url = hash[0] + separator + key + '=' + value;
            if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
                url += '#' + hash[1];
            }
            return url;
        } else {
            return url;
        }
    }
};

/**
 * Convert given path to a full CloudFront URL and add a query parameter to prevent cache responses between content loaded in DOM
 *  and content loaded in Javascript to prevent CORS issues.
 * @param {string} path path to use to build the CloudFront URL
 * @param {boolean} forDomLoad if true, the query parameter will indicate the URL will be used to load content from DOM
 *  instead of from Javascript, defaults to false.
 */
export const buildCloudFrontUrl = (path: string, forDomLoad = false) => {
    // Remove /Content folder
    path = path.replace('/Content', '');
    if (path.startsWith('Content')) {
        path = path.replace('Content', '');
    }

    // Trim slashes
    const cdnUrl = config.CUSTOMILY_CDN_URL.replace(/^\/+|\/+$/g, '');
    path = path.replace(/^\/+|\/+$/g, '');

    return updateQueryString('x-request', forDomLoad ? 'cl-core-html' : 'cl-core-js', `${cdnUrl}/${path}`);
};
