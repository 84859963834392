import Vue from 'vue';
import VueRouter from 'vue-router';
import { ErrorHandler, NavigationFailure, RawLocation, Route } from 'vue-router/types/router';
import routes from './routes';

type OnComplete = ((route: Route) => void) | undefined;

// Rewrite Router push method to prevent throwing errors when navigating to the route we are already in which throws an error like:
//  "NavigationDuplicated: Navigating to current location ("/sets") is not allowed"
const originalPush = VueRouter.prototype.push;
(VueRouter.prototype.push as (
    location: RawLocation,
    onComplete?: OnComplete,
    onAbort?: ErrorHandler
) => void | Promise<Route | NavigationFailure>) = function push(
    this: unknown,
    location: RawLocation,
    onResolve?: OnComplete,
    onReject?: ErrorHandler
) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }

    return (originalPush as (location: RawLocation) => Promise<Route>).call(this, location).catch((error) => {
        if (VueRouter.isNavigationFailure(error)) {
            // resolve error
            return error;
        }

        // rethrow error
        return Promise.reject(error);
    });
};

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});

export default router;
