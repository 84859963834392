<template>
    <div v-if="stores && stores.length" class="store-selector-wrapper">
        <b-dropdown
            ref="storesDropdown"
            split
            variant="primary"
            :text="storeSelectorTitle"
            @click="onStoresDropdownClick()"
        >
            <!-- All stores selector currently disabled -->
            <!-- <b-dropdown-item v-if="allowAllStoresSelector" goToAppInstallLink:active="store == null" @click="setStoreUrl(null)">
                All stores
            </b-dropdown-item> -->

            <b-dropdown-item
                v-for="store in stores"
                :key="`store-${store.Id}`"
                :value="store.Url"
                :active="$store.state.store == store.Url"
                @click="setStoreUrl(store.Url)"
            >
                <div>{{ store.Url }}</div>
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script setup lang="ts">
import { Ref, computed, onBeforeMount, ref } from 'vue';
import { useStore } from '@/store';
import type { BDropdown } from 'bootstrap-vue/src/components/dropdown';
import type { StoreType } from '@extTypes/api/customily/user';

const emit = defineEmits<{
    (event: 'store-selected', value: string | null): void;
}>();

const props = defineProps<{
    storeTypes?: StoreType[];
}>();

const { state, commit } = useStore();

const storesDropdown: Ref<BDropdown | null> = ref(null);

/**
 * this getter maybe is unnecesary, because store always will
 * have value whenever selector is visible
 */
const storeSelectorTitle = computed(() => state.store || 'All stores');

const stores = computed(() => {
    const userStores = state.user?.customilyUser.Stores || [];
    return props.storeTypes ? userStores.filter((s) => props.storeTypes!.includes(s.Type)) : userStores;
});

onBeforeMount(() => {
    const isAvailableStore = stores.value.some((s) => s.Url == state.store);
    if (!isAvailableStore && stores.value.length) {
        commit('setStore', stores.value[0].Url);
    }
});

const onStoresDropdownClick = () => {
    storesDropdown.value?.show();
};

const setStoreUrl = (store: string | null) => {
    commit('setStore', store);
    emit('store-selected', store);
};
</script>

<style lang="scss" scoped>
.store-selector-wrapper {
    :deep() {
        .dropdown {
            white-space: nowrap;

            button {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 300px;
            }

            .dropdown-menu {
                max-height: 90vh;
                overflow-y: auto;
            }
        }
    }
}
</style>
