import api from '../baseAPIs/shopify';
import { CustomilyUser } from '@extTypes/api/customily/user';

export interface ApiUser {
    client_id: number;
    client_actual_domain: string;
    last_product_sync: Date;
    first_time_product_created: boolean;
    first_time_set_created: boolean;
    show_shopify_products_function: boolean;
    customilyUser: CustomilyUser;
    stamp_cookie: string;
}

export interface StoreAuthStatus {
    store: string;
    authenticated: boolean;
}

export const getUser = async (): Promise<ApiUser> => {
    const response = await api.get('user');
    return response.data;
};

export const isStoreAuthenticated = async (store: string): Promise<StoreAuthStatus> => {
    const response = await api.get('user/stores/validAuth', {
        params: {
            store: store
        }
    });
    return response.data;
};
