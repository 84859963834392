import axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import urljoin from 'url-join';
import { cache } from './baseApi';

const axiosInstance = axios.create({
    baseURL: urljoin(window.location.origin, 'api'),
    responseType: 'json',
    adapter: cache.adapter
});
axiosBetterStacktrace(axiosInstance);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error?.response?.status === 401) {
            document.dispatchEvent(new Event('auth-error'));
        }
        throw error;
    }
);

export default axiosInstance;
