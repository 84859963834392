import { LinkedProductsOption } from '@extTypes/enums/products';

export const productLinkedOptions = [
    {
        value: LinkedProductsOption.All,
        text: 'All products'
    },
    {
        value: LinkedProductsOption.Linked,
        text: 'Linked to Customily'
    },
    {
        value: LinkedProductsOption.NotLinked,
        text: 'Not linked to Customily'
    }
];
