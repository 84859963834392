import { RouteConfig } from 'vue-router';

export default [
    {
        path: '/affiliates',
        component: () => import('@/components/shared/EmptyWrapper.vue'),
        meta: { requireStore: false },
        children: [
            {
                path: '',
                name: 'AffiliatesDashboard',
                component: () => import(/* webpackChunkName: "affiliates" */ '@/views/Affiliates/Dashboard.vue')
            }
        ]
    },
    {
        path: '/earnings',
        component: () => import('@/components/shared/EmptyWrapper.vue'),
        meta: { requireStore: false },
        children: [
            {
                path: '',
                name: 'Earnings',
                component: () => import(/* webpackChunkName: "earnings" */ '@/views/Earnings/index.vue')
            }
        ]
    }
] as RouteConfig[];
