import api from '../baseAPIs/shopify';
import { OauthTokenResponse } from '@extTypes/etsy/auth';

export const getEtsyLogin = (): Promise<OauthTokenResponse> =>
    api.get<OauthTokenResponse>('etsy/requestToken').then(({ data }) => data);

export const setEtsyRequestToken = async (tokenSecret: string, state: string, shop: string): Promise<string> => {
    const response = await api.put(
        'etsy/requestToken',
        {
            tokenSecret,
            state
        },
        {
            params: {
                shop: shop
            }
        }
    );
    return response.data.login_url;
};

export const getEtsyAccessToken = (oauthToken: string, state: string): Promise<void> =>
    api.get('etsy/accessToken', {
        params: {
            oauthToken: oauthToken,
            state: state
        }
    });

export const getEtsyShopName = async (shop: string): Promise<string> => {
    const response = await api.get('etsy/shopName', {
        params: {
            shop: shop
        }
    });
    return response.data.shopName;
};
export const hasProductionPartners = async (
    shop: string,
    pod: string
): Promise<{ exists: boolean; partner_id: number }> => {
    const response = await api.get('etsy/productionPartners', {
        params: {
            shop: shop,
            pod: pod
        }
    });
    return response.data;
};
