import { RouteConfig } from 'vue-router';

export default [
    {
        path: '/orders',
        component: () => import('@/components/shared/EmptyWrapper.vue'),
        children: [
            {
                path: '',
                name: 'OrderManager',
                component: () => import(/* webpackChunkName: "orders" */ '@/views/OrderManager/OrderManager.vue')
            },
            {
                path: 'shopify-detail/:orderItemId',
                name: 'ShopifyOrderDetail',
                component: () =>
                    import(/* webpackChunkName: "orderDetail" */ '@/views/OrderManager/Shopify/OrderDetails.vue')
            },
            {
                path: 'custom-detail/:orderItemId',
                name: 'CustomOrderDetail',
                component: () =>
                    import(/* webpackChunkName: "orderDetail" */ '@/views/OrderManager/CustomStore/Orders.vue')
            },
            {
                path: 'custom-order-review/:orderItemId',
                name: 'CustomOrderReview',
                component: () =>
                    import(
                        /* webpackChunkName: "orderCustomReview" */ '@/views/OrderManager/CustomStore/OrderReview.vue'
                    )
            }
        ]
    }
] as RouteConfig[];
