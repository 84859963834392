import { RouteConfig } from 'vue-router';

export default [
    {
        path: '/templates',
        component: () => import('@/components/shared/EmptyWrapper.vue'),
        children: [
            {
                path: '',
                name: 'Templates',
                component: () => import(/* webpackChunkName: "templates" */ '@/views/Templates/Templates.vue')
            }
        ]
    },
    {
        path: '/setsViewer',
        component: () => import('@/components/shared/EmptyWrapper.vue'),
        children: [
            {
                path: '',
                name: 'SetsViewer',
                component: () => import(/* webpackChunkName: "setsViewer" */ '@/views/SetsViewer/SetsViewer.vue')
            }
        ]
    }
] as RouteConfig[];
