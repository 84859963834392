<template>
    <div>
        <BlockUI v-show="showLoader" :message="loaderMessage" :url="loaderUrl" />
    </div>
</template>

<script lang="ts">
import { DebouncedFunc, debounce } from 'lodash';
import BlockUI from 'vue-blockui';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

Vue.use(BlockUI);
@Component({
    name: 'Loader'
})
export default class Loader extends Vue {
    @Prop({ default: 'Loading' }) readonly message!: string;
    @Prop({ default: false }) readonly show!: boolean;

    showLoader = false;
    debouncedShowChange: DebouncedFunc<(show: boolean) => void>;

    constructor() {
        super();
        this.debouncedShowChange = debounce((show: boolean) => {
            this.showLoader = show;
        }, 300);
    }

    @Watch('show', { immediate: true })
    onShowChange(newValue: boolean) {
        this.showLoader = newValue;
        // this.debouncedShowChange(newValue);
    }

    get loaderMessage(): string {
        return this.message;
    }

    get loaderUrl(): string {
        return require('./loader.gif');
    }
}
</script>

<style></style>
