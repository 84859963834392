import { Store, StoreType } from '@extTypes/api/customily/user';

export const isStoreOfType = (shop: string, stores: Store[], storeType: StoreType) => {
    return stores.some((s) => s.Url === shop && s.Type === storeType);
};

export const isWooCommerceStore = (shop: string, stores: Store[]) => {
    const store = stores.find((s) => {
        return s.Url === shop;
    });
    return store && store.Type === StoreType.WooCommerce;
};

export const hasWooCommerceStores = (stores: Store[]) => {
    return stores.some((s) => s.Type === StoreType.WooCommerce);
};

export const isValidStoreTypes = (stores: Store[], types: StoreType[]) => {
    return stores.some((s) => types.includes(s.Type));
};

export const isEtsyStore = (shop: string, stores: Store[]) => {
    return stores.some((s) => s.Url === shop && s.Type === StoreType.Etsy);
};

export const isCustomStore = (shop: string, stores: Store[]) => {
    return stores.some((s) => s.Url === shop && s.Type === StoreType.Custom);
};

export const isShoplazzaStore = (shop: string, stores: Store[]) => {
    return stores.some((s) => s.Url === shop && s.Type === StoreType.Shoplazza);
};

export const isAmazonStore = (shop: string, stores: Store[]) => {
    return isStoreOfType(shop, stores, StoreType.Amazon);
};

export const isTiktokStore = (shop: string, stores: Store[]) => {
    return isStoreOfType(shop, stores, StoreType.Tiktok);
};

export const isShopify = (shop: string, stores: Store[]) => {
    return isStoreOfType(shop, stores, StoreType.Shopify);
};
