import api from '../baseAPIs/shopify';
import expimpApi from '../baseAPIs/impexp';
import { OptionSetDto, OptionSetCreateApi } from '@extTypes/options/optionSet';

/**
 * Get option sets for the current user
 */
export const getOptionSets = async (
    searchString: string,
    from: number,
    count: number,
    forDesignTemplate = false,
    store?: string
): Promise<OptionSetDto[]> => {
    const response = await api.get('optionsets', {
        params: {
            searchString: searchString,
            from: from,
            count: count,
            shop: store,
            forDesignTemplate: forDesignTemplate
        }
    });

    return response.data;
};

export const getProductsWithSet = async (
    setId: string
): Promise<
    {
        title: string;
        id: string;
        isDesign: boolean;
    }[]
> => {
    const response = await api.get(`optionsets/${setId}/products`);
    return response.data;
};

export const getOptionSetsCount = async (
    searchString: string,
    forDesignTemplate = false,
    store?: string
): Promise<{
    count: number;
}> => {
    const response = await api.get('optionsets/count', {
        params: {
            searchString: searchString,
            shop: store,
            forDesignTemplate: forDesignTemplate
        }
    });

    return response.data;
};

export const getOptionSet = async (setId: string, includeOptions: boolean): Promise<OptionSetDto> => {
    const response = await api.get('optionsets/private/' + setId, {
        params: {
            includeOptions: includeOptions
        }
    });

    return response.data;
};

export const addOptionSet = async (set: OptionSetCreateApi, forDesignTemplate = false): Promise<number> => {
    const response = await api.post('optionsets', set, {
        params: {
            forDesignTemplate: forDesignTemplate
        }
    });
    // Created set id
    return response.data.id;
};

export const saveOptionSet = async (set: OptionSetDto): Promise<number> => {
    const response = await api.put('optionsets', set);
    // Saved set id
    return response.data;
};

export const cloneOptionSet = async (set: OptionSetDto, forDesignTemplate = false): Promise<number> => {
    const response = await api.post(`optionsets/${set.id}`, set, { params: { forDesignTemplate } });
    // Cloned set id
    return response.data;
};

export const deleteSet = async (set: OptionSetDto): Promise<void> => {
    await api.delete('optionsets/' + set.id);
    return;
};

export const createSetFromTemplate = async (
    templateId: string,
    isManaged: boolean,
    title: string,
    productId?: string,
    storeUrl?: string
): Promise<{
    setId: string;
    taskIdentifier: string;
}> => {
    const response = await api.post('optionsets/createSetFromTemplate/' + templateId, {
        isManaged,
        title,
        productId,
        storeUrl
    });
    return response.data;
};

export const getProgress = async (progressIdentifier: string): Promise<number | null> => {
    const response = await api.get('/optionsets/progress/' + progressIdentifier);
    return response.data.progress;
};

export const getProgressInfo = async (
    productId: string
): Promise<{ progress: number; identifier: string; templateId: string; error: boolean } | null> => {
    const response = await api.get(`/optionsets/progress/info/${productId}`);
    return response.data;
};

// TODO: add typings for return
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const exportOptionSets = async (
    shop: string,
    filters: string
): Promise<{
    exportUrl: string;
}> => {
    const data = { domain: shop, appVersion: 'new', filters: filters };
    const response = await expimpApi.post('options/export', data, { timeout: 1000 * 60 * 15 });
    return response.data;
};

// TODO: add typings for return
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const importOptionSets = async (shop: string, file: File): Promise<any> => {
    const formData = new FormData();
    formData.append('zipFile', file);
    const resp = await expimpApi.post('options/import/uploadFile', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        timeout: 1000 * 60 * 5
    });

    if (resp && resp.data.fileName) {
        const postData = { domain: shop, fileName: resp.data.fileName, appVersion: 'new' };
        const response = await expimpApi.post('options/import', postData, { timeout: 1000 * 60 * 30 });
        return response.data;
    }

    return false;
};

export const hasValueNameAndTooltipSwatches = async (): Promise<{
    hasValueNameAndTooltipSwatches: boolean;
}> => {
    const response = await api.get('optionsets/valueTooltip');
    return response.data;
};

export const generateOptionLayer = async (
    layer: {
        uuid: string;
    },
    template: string,
    setId?: string,
    forDesignTemplate?: boolean
): Promise<{
    setId: string;
    taskIdentifier: string;
}> => {
    const response = await api.post(`optionsets/optionGenerateLayer/${setId}`, {
        template,
        layer: layer.uuid,
        forDesignTemplate
    });
    return response.data;
};
