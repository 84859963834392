import Vue from 'vue';
import Component from 'vue-class-component';
import ProductsList from '@/components/ProductsList/ProductsList.vue';
import { DTO } from '@extTypes/dto/productDto';

@Component({
    name: 'Products',
    components: { ProductsList }
})
export default class Products extends Vue {
    onBackButton() {
        this.$router.push('/');
    }

    onProductSelect(data: { product: DTO.ShopifyProductList; store: string; isPodSelectionFlow: boolean }) {
        // If the URL has a lastCreatedTemplateId query param it means that it will automatically load the template
        // that corresponds to that id in the set selection page
        const lastCreatedTemplateId = this.$route.query.lastCreatedTemplateId;

        // Go to the set selection page
        this.$router.push({
            path: '/product',
            query: {
                product: data.product.id.toString(),
                lastCreatedTemplateId,
                isPodSelectionFlow: data.isPodSelectionFlow.toString()
            }
        });
    }
}
