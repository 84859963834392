import { RouteConfig } from 'vue-router';

export default [
    {
        path: '/analytics',
        component: () => import('@/components/shared/EmptyWrapper.vue'),
        children: [
            {
                path: '',
                name: 'Analytics',
                component: () => import(/* webpackChunkName: "analytics" */ '@/views/Analytics/AnalyticsDashboard.vue')
            },
            {
                path: 'sales',
                name: 'SalesReport',
                component: () => import(/* webpackChunkName: "salesReport" */ '@/views/Analytics/SalesReport.vue')
            }
        ]
    }
] as RouteConfig[];
