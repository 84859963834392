import axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

const axiosInstance = axios.create({
    baseURL: 'https://api.customily.com/sh_app',
    responseType: 'json'
});

axiosBetterStacktrace(axiosInstance);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            document.dispatchEvent(new Event('auth-error'));
        }
        throw error;
    }
);

export default axiosInstance;
